<template>
    <div class="content download">
        <Breads :breads="breads"></Breads>
        <div class="download" v-loading="loading">
            <div class="box-title">下载专区</div>
            <div class="box-content">
                <div class="down-item" v-for="(item, index) in items.list" :key="index">
                    <a :href="'/core/base/c/download/file?attachId='+item.attachInfoId"  class="down-link">{{item.attachName}}</a>
                    <span class="down-date">{{item.createTime}}</span>
                </div>
                
                
            </div>
            <Pager :currentPage="currentPage" :totalPage="items.totalPage"  @pageChange="pageChange"></Pager>
        </div>
    </div>
</template>

<script>
import Pager from '../components/common/Pager'
import Breads from '../components/common/Bread'
import {ref, getCurrentInstance, onMounted} from 'vue'
export default {
    components: {Pager, Breads},
    setup(){
        const loading = ref(false)
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1)

        const breads = ref([{
            text:'竞赛专区',
            name: 'Contest',
            path: '/contest'
        }, {text:'下载专区'}])

        const items = ref({
            total: 0,
            totalPage: 0,
            list:[]
        })

        function getDownloadList(){
            const url = '/core/base/a/attach/filelist?relateType=CPT-DOWNLOAD-ZONE&relateId=CPT-DOWNLOAD-ZONE';
            loading.value = true;
            proxy.$http.post(url, {pageSize: 10, pageIndex: currentPage.value}).then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    items.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            getDownloadList();
        }

        onMounted(()=>{
            getDownloadList();
        })

        return {breads, loading, items, currentPage, pageChange};
    }
}
</script>

<style scoped>
.download{
    padding-bottom: 97px;
}
.box-title{
    margin-bottom: 16px;
}
.down-link{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #007EEC;
    letter-spacing: 0;
    text-align: justify;
    margin-bottom: 12px;
     max-width: 440px;
    overflow: hidden;
    text-decoration: none;
}
.down-date{
    font-family: SourceHanSansCN-Normal;
    font-size: 14px;
    color: rgba(0,0,0,0.80);
    line-height: 14px;
}
.down-item{
    display: flex;
    justify-content: space-between;
}
</style>
